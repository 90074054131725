import React, { useState, useEffect } from "react";
import "./Home.css";
import Layout from "./Layout";
import ProjectSection from "./ProjectSection";

function Takoda() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("https://api.takodamundy.tech/git")
      .then((response) => response.json())
      .then((data) => setProjects(data))
      .catch((error) => console.error("Error fetching projects:", error));
  }, []);

  return (
    <>
      <Layout>
        <div className="title">
          <p className="intro">Hi, my name is</p>
          <div className="name">Takoda Mundy</div>
          <div className="actionStatement">Revolutionising with data.</div>
          <div className="para">
          I am passionate about unlocking the transformative power of data, machine learning, and cloud technologies. 
          My focus is on applying these advanced 
          tools to unearth hidden opportunities and drive intelligent decision-making in complex business environments. 
          To set new benchmarks in operational excellence and strategic insight across diverse industries.
          </div>
        </div>
        <div className="box aboutSec">
          <div className="about">1. About me</div>
          <div className="aboutInner">
            <div className="para aboutPara">
            Hello, I'm Takoda. Originally from the Sunshine Coast, 
            I've recently graduated with a distinction in Computer Science from Queensland University of Technology. 
            My studies were centered around Data analytics and Enterprise IoT,
            When I'm not immersed in technology, I enjoy wakeboarding and playing football.
            In the professional sphere, I am eager to apply my knowledge and skills in practical settings, 
            particularly in leveraging data and new technologies for effective decision-making. Now living in Brisbane, 
            I'm ready to dive into roles where I can make a meaningful impact using the 
            insights and experience gained from my education and personal tech explorations.
            </div>
            <div className="para aboutPara">
              {" "}

            </div>
          </div>
        </div>
        <div className="box aboutSec">
          <div className="about">2. Projects</div>
          <ProjectSection projects={projects} />
        </div>
      </Layout>
    </>
  );
}

export default Takoda;
